import { cloneElement } from 'react';

import { get } from 'lodash';

import { INTERVENTIONS_IDS, ITEM_INTERVENTIONS, VERTICAL_TYPE } from '../../../constants';
import { ESTIMATED_CONTENT_WIDTHS } from '../constants';

export const mapWithKey = (components, keyPrefix) =>
  // eslint-disable-next-line react/no-array-index-key
  components.map((component, index) => component && cloneElement(component, { key: `${keyPrefix}-${index}` }));

export const IsNotCropImage = (vertical, image_ratio) =>
  (vertical === VERTICAL_TYPE.MOT || vertical === VERTICAL_TYPE.SRV) && image_ratio > 1.6;

export const getEstimatedCardContentWidth = (device, layout) =>
  get(ESTIMATED_CONTENT_WIDTHS, `${device}.${layout}`, ESTIMATED_CONTENT_WIDTHS.DEFAULT);

export const isItemIntervention = (item) => ITEM_INTERVENTIONS.includes(item.id);

export const isIntervention = (elem) => elem && INTERVENTIONS_IDS.includes(elem.id);
