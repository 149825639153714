import React from 'react';
import { arrayOf, bool, shape, string } from 'prop-types';

import { PolycardProvider } from '../../../polycard/componentes/provider';
import { PolyCardContextPropTypes } from '../../../polycard/prop-types/polycard-context.prop-types';
import { layoutNamespace } from '../../constants';
import { ResultPropTypes } from '../../constants/result.prop-types';
import { ItemResult } from '../item-result';
import { CountryPropTypes } from '../../constants/country.prop-types';
import { withComponentHandler } from '../../../../hocs/with-component-handler';

/**
 * @typedef {Object} OneColumnLayoutProps
 * @property {string} className
 * @property {Object} country
 * @property {string} deviceType
 * @property {boolean} isPolycard
 * @property {boolean} isShops
 * @property {string} layout
 * @property {Object} options
 * @property {boolean} options.lowEnd
 * @property {boolean} options.verboseLabels
 * @property {boolean} options.cardSupportsLinks
 * @property {Object} polycard_context
 * @property {Array} results
 * @property {string} vertical
 */

/**
 * OneColumnLayout component renders a list of results in a single column layout.
 *
 * @param {OneColumnLayoutProps} props
 * @returns {JSX.Element} The rendered one column layout component.
 */
export const OneColumnLayoutComponent = ({
  className,
  country,
  deviceType,
  isPolycard,
  isShops,
  layout,
  options,
  polycard_context = null,
  results = [],
  vertical,
}) => (
  <PolycardProvider contextValue={polycard_context} layout={layout} deviceType={deviceType} countryConfig={country}>
    <ol className={className}>
      {results?.map((item, index) => (
        <ItemResult
          // eslint-disable-next-line react/no-array-index-key
          key={`${item.id}-${index}`}
          item={item}
          index={index}
          deviceType={deviceType}
          options={options}
          namespace={layoutNamespace}
          layout={layout}
          isPolycard={isPolycard}
          vertical={vertical}
          isShops={isShops}
        />
      ))}
    </ol>
  </PolycardProvider>
);

OneColumnLayoutComponent.propTypes = {
  className: string,
  country: CountryPropTypes,
  deviceType: string,
  isPolycard: bool,
  isShops: bool,
  layout: string.isRequired,
  options: shape({ lowEnd: bool, verboseLabels: bool, cardSupportsLinks: bool }),
  polycard_context: PolyCardContextPropTypes,
  results: arrayOf(ResultPropTypes),
  vertical: string,
};

export const OneColumnLayout = withComponentHandler(OneColumnLayoutComponent, {
  componentName: 'OneColumnLayout',
});
