import React from 'react';
import { node, string } from 'prop-types';

import { PolycardContext } from '@polycard/card';

import { PRELOAD_MOBILE, DEVICE_TYPES, PRELOAD_DESKTOP } from '../../../constants';
import { CountryPropTypes } from '../../layout/constants/country.prop-types';
import { PolyCardContextPropTypes } from '../prop-types/polycard-context.prop-types';

/**
 * @typedef {Object} CountryConfig
 * @property {string} thousands_separator
 * @property {string} decimal_separator
 */

/**
 * PolycardProvider component
 *
 * @param {Object} props
 * @param {React.ReactNode} props.children
 * @param {Object} props.contextValue
 * @param {string} props.deviceType
 * @param {string} props.layout
 * @param {CountryConfig} props.countryConfig
 * @returns {JSX.Element}
 */
export const PolycardProvider = ({
  children = null,
  contextValue = null,
  deviceType = DEVICE_TYPES.MOBILE,
  layout = 'grid',
  countryConfig = null,
}) => {
  const numberOfPreload = deviceType === DEVICE_TYPES.MOBILE ? PRELOAD_MOBILE[layout] : PRELOAD_DESKTOP[layout];

  return contextValue ? (
    <PolycardContext
      contextValue={{ ...contextValue, title_tag: 'h2' }}
      deviceType={deviceType}
      isNavigableToVariation
      countPreloadImages={numberOfPreload}
      thousandSeparator={countryConfig?.thousands_separator}
      decimalSeparator={countryConfig?.decimal_separator}
    >
      {children}
    </PolycardContext>
  ) : (
    <>{children}</>
  );
};

PolycardProvider.propTypes = {
  children: node,
  contextValue: PolyCardContextPropTypes,
  countryConfig: CountryPropTypes,
  deviceType: string,
  layout: string,
};
