import { arrayOf, number, object, shape, string } from 'prop-types';

export const Currency = shape({
  id: string,
  symbol: string,
  description: string,
  decimal_places: string,
});

export const Location = shape({
  latitude: number,
  longitude: number,
});

export const GeoInformation = shape({
  location: Location,
});

export const State = shape({
  id: string,
  name: string,
});

export const CountryPropTypes = shape({
  id: string,
  name: string,
  locale: string,
  currency_id: string,
  decimal_separator: string,
  thousands_separator: string,
  time_zone: string,
  geo_information: GeoInformation,
  states: arrayOf(State),
  currencies: object,
  andesLocale: string,
});
