import React, { memo } from 'react';
import { bool, shape, string } from 'prop-types';

import { loadable } from 'nordic/lazy';

import { withComponentHandler } from '../../../../hocs/with-component-handler';
import { ResultPropTypes } from '../../constants/result.prop-types';
import { isIntervention } from '../../utils';

/* istanbul ignore next */
const Item = loadable(
  async () =>
    /* webpackChunkName: "chunk-item-items" */
    import(`./item`),
  {
    resolveComponent: (components) => components.Item,
  },
);

/* istanbul ignore next */
const Interventions = loadable(
  async () =>
    /* webpackChunkName: "chunk-item-Interventions" */
    import('../../../intervention/render'),
  {
    resolveComponent: (components) => components.Interventions,
  },
);

/**
 * @typedef {Object} ItemResultComponentProps
 * @property {string} deviceType
 * @property {string} index
 * @property {boolean} isPolycard
 * @property {boolean} isShops
 * @property {Object} item - Required
 * @property {string} layout
 * @property {string} namespace
 * @property {Object} options
 * @property {boolean} options.lowEnd
 * @property {boolean} options.verboseLabels
 * @property {boolean} options.cardSupportsLinks
 * @property {string} vertical
 */

/**
 * ItemResultComponent - Renders an item result based on its type.
 *
 * @param {ItemResultComponentProps} props
 * @returns {JSX.Element}
 */
const ItemResultComponent = (props) => {
  const { item } = props;

  if (isIntervention(item)) {
    return <Interventions {...props} />;
  }

  return <Item {...props} />;
};

ItemResultComponent.propTypes = {
  deviceType: string,
  index: string,
  isPolycard: bool,
  isShops: bool,
  item: shape(ResultPropTypes).isRequired,
  layout: string,
  namespace: string,
  options: shape({ lowEnd: bool, verboseLabels: bool, cardSupportsLinks: bool }),
  vertical: string,
};

export const ItemResultMemo = memo(ItemResultComponent);

export const ItemResult = withComponentHandler(ItemResultMemo, {
  componentName: 'ItemResult',
});
