import { string } from 'prop-types';

export const PolyCardContextPropTypes = {
  contract_version: string,
  picture_template: string,
  picture_size_default: string,
  url_prefix: string,
  type: string,
  thousand_separator: string,
  decimal_separator: string,
  picture_square_default: string,
  url_fragments_default: string,
};
