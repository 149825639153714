import PropTypes, { arrayOf, string, shape, number } from 'prop-types';

export const AnalyticsTrack = shape({
  action: string,
  category: string,
  label: string,
  type: string,
});

export const EventData = shape({
  to_name: string,
  to_position: number,
});

export const MelidataTrack = shape({
  path: string,
  type: string,
  event_data: EventData,
});

export const Action = shape({
  target: string,
  label: string,
  tracks: MelidataTrack,
});

export const Tracks = shape({
  melidata_track: MelidataTrack,
  analytics_track: AnalyticsTrack,
});

export const ParentType = PropTypes.oneOf(['CPG']);

export const Tags = shape({
  alt: string,
});

export const Picture = shape({
  normal: string,
  retina: string,
  tags: Tags,
});

export const Price = shape({
  currency: string,
  amount: number,
  discount: number,
  original_price: number,
});

export const Value = shape({
  type: string,
  title: string,
  subtitle: string,
  picture: Picture,
  action: Action,
  parent_type: ParentType,
  id: string,
  price: Price,
});

export const Content = shape({
  id: string,
  type: string,
  state: string,
  content: shape({}),
  values: arrayOf(Value),
});

export const ResultPropTypes = {
  id: string,
  type: string,
  state: string,
  content: Content,
  values: shape({ id: string, type: string, state: string, content: Content, values: arrayOf(Value) }),
};
